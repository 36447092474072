import { createContext, useContext, useState } from "react";

const LoginModeContext = createContext();

export const LoginModeProvider = ({ children }) => {
    const [loginMode, setLoginMode] = useState('user');
    return (
        <LoginModeContext.Provider value={{ loginMode, setLoginMode }}>
            {children}
        </LoginModeContext.Provider>
    )
}

export const useLoginMode = () => useContext(LoginModeContext)