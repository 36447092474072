import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {useParams} from 'react-router-dom';
import {
  CAPLEAD_ADD_BULK_EXHIBITORS_URL,
  CAPLEAD_ADD_EXHIBITORS_URL,
  CAPLEAD_DELETE_EXHIBITOR_URL,
  CAPLEAD_EXHIBITOR_SEARCH_URL,
  CAPLEAD_EXPORT_EXHIBITORS_URL,
  CAPLEAD_GET_EXHIBITION_URL,
  CAPLEAD_GET_EXHIBITORS_URL,
  CAPLEAD_RESEND_EMAIL_EXHIBITOR_URL,
  CAPLEAD_UPDATE_EXHIBITOR_URL,
} from '../../api';
import {Delete, Get, GetExport, Post, Update} from '../../axios';
import {AlertMessageContext} from '../aletMessage/AlertMessageProvider';
import {UserContext} from '../user/UserProvider';

export const ExhibitiorContext = createContext();
const ExhibitorProvider = ({children}) => {
  const [exhibitionById, setExhibitionById] = useState([]);
  const [exhibitors, setExhibitors] = useState([]);
  const [loadingExhibitors, setLoadingExhibitors] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const {tokendata} = useContext(UserContext);
  const [exhibitorSearchData, setExhibitorsSearchData] = useState([]);
  const [selectedExhibitorPage, setSelectedExhibitorPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const {showErrorMessage, showSuccessMessage} =
    useContext(AlertMessageContext);
  const params = useParams();
  // this function is add bulk exhibitors data  for specific exhibition by exhibitionId
  const addBulkExhibitors = async (exhibitionId, file) => {
    const headers = {'x-access-token': tokendata};
    const formData = new FormData();
    formData.append('file', file);
    formData.append('exhibitionId', exhibitionId);
    try {
      const response = await Post(
        CAPLEAD_ADD_BULK_EXHIBITORS_URL,
        formData,
        headers,
      );
      showSuccessMessage('Bulk Exhibitor added successfully');
      return response.data;
    } catch (error) {
      showErrorMessage('Bulk Exhibitor not added');
    }
  };

  // this function is used to add individual exhibitors data  for specific exhibition by exhibitionId
  const addExhibitor = async (exhibitionId, data) => {
    const headers = {'x-access-token': tokendata};
    const formData = {
      exhibitionId: exhibitionId,
      ...data,
    };
    try {
      const response = await Post(
        CAPLEAD_ADD_EXHIBITORS_URL,
        formData,
        headers,
      );
      if (response.status.code === 201) {
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
      return response.data;
    } catch (error) {
      showErrorMessage('Exhibitor not added');
    }
  };

  // this function is get exhibitors for specific exhibition by exhibitionId
  const getExhibitors = useCallback((exhibitionId, currentPage) => {
    try {
      const headers = {'x-access-token': tokendata};
      const url =
        CAPLEAD_GET_EXHIBITORS_URL +
        '/' +
        exhibitionId +
        '?page=' +
        currentPage;
      Get(url, headers)
        .then(response => {
          setSelectedExhibitorPage(currentPage);
          setExhibitors(response.data.data);
          setExhibitorsSearchData(response.data.data);
        })
        .finally(() => {
          setTimeout(() => {
            setLoadingExhibitors(false);
          }, 500);
        });
    } catch (error) {
      showErrorMessage('api error');
    }
  }, []);

  const exhibitorPage = async currentPage => {
    if (searchText) {
      searchExhibitors(params.id, currentPage, searchText);
    } else {
      getExhibitors(params.id, currentPage);
    }
  };

  // this function is update exhibitors data  for specific exhibitor by exhibitorId
  const updateExhibitor = async (
    data,
    exhibitorId,
    exhibitionId,
    currentPage,
  ) => {
    const headers = {'x-access-token': tokendata};
    const url =
      CAPLEAD_UPDATE_EXHIBITOR_URL +
      '/' +
      exhibitorId +
      '?exhibitionId=' +
      exhibitionId;

    return Update(url, data, headers)
      .then(response => {
        if (response.data.status.code === 200) {
          showSuccessMessage(response.data.status.message);
          getExhibitors(exhibitionId, currentPage);
          return response;
        } else {
          showErrorMessage(response.data.status.message);
        }
      })
      .catch(error => {
        showErrorMessage('api error');
      });
  };

  const resendEmail = async (exhibitorId) => {
    try {
      const headers = {'x-access-token': tokendata};
      const url = CAPLEAD_RESEND_EMAIL_EXHIBITOR_URL + '/' + exhibitorId;
      return Post(url, {}, headers)
        .then(response => {
          if (response?.status?.code === 200) {
            showSuccessMessage(response?.status?.message);
            getExhibitors(exhibitorId);
            return response;
          } else {
            showErrorMessage(response?.status?.message);
          }
        })
        .catch(error => {
          showErrorMessage('api error');
        });
    } catch (error) {
      showErrorMessage('error');
    }
  };

  // this function is delete exhibitors data  for specific exhibitor by exhibitorId
  const deleteExhibitor = async exhibitorId => {
    try {
      const headers = {'x-access-token': tokendata};
      const url = `${CAPLEAD_DELETE_EXHIBITOR_URL}/${exhibitorId}`;
      await Delete(url, headers);
      updateExhibitorData(exhibitorId);
      showSuccessMessage('Exhibitor deleted successfully');
    } catch (error) {
      showErrorMessage('api error');
    }
  };

  // this function is used to update exhibitors data locally after delete exhibitor by exhibitorId
  const updateExhibitorData = async exhibitorId => {
    const removeDeletedExhibitor = exhibitors?.exhibitors.filter(
      exhibitor => exhibitor.id !== exhibitorId,
    );
    let data = {
      currentPage: exhibitors.currentPage,
      exhibitors: removeDeletedExhibitor,
      totalItems: exhibitors.totalItems - 1,
      totalPages: exhibitors.totalPages,
    };
    setExhibitors(data);
  };

  // this function is export exhibitors data  for specific exhibition by exhibitionId
  const exportExhibitorsData = async id => {
    try {
      const headers = {
        'x-access-token': tokendata,
      };
      const url = CAPLEAD_EXPORT_EXHIBITORS_URL + '/' + id;
      const response = await GetExport(url, headers);
      const mainblob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const mainurl = URL.createObjectURL(mainblob);
      const disposition = response.headers['content-disposition'];
      const filename =
        disposition?.match(/filename=([^;]+)/i)?.[1]?.trim() || 'my-file.xlsx';
      const link = document.createElement('a');
      link.href = mainurl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      showSuccessMessage('Exhibitors exported successfully');
    } catch (error) {
      showErrorMessage('api error');
    }
  };

  const searchExhibitors = async (
    exhibitionId,
    currentPage = 1,
    searchToken,
  ) => {
    if (searchToken === '') {
      getExhibitors(params.id, 1);
    } else {
      const headers = {'x-access-token': tokendata};
      const url =
        CAPLEAD_EXHIBITOR_SEARCH_URL +
        '?exhibitionId=' +
        exhibitionId +
        '&searchTerm=' +
        searchToken +
        '&page=' +
        currentPage;
      Get(url, headers)
        .then(res => {
          setExhibitors(res.data.data);
          setSelectedExhibitorPage(res.data.data.currentPage);
        })
        .catch(error => {
          showErrorMessage('search exhibitor error');
        });
    }
  };

  const getExhibitionById = id => {
    const headers = {
      'x-access-token': tokendata,
    };
    const url = CAPLEAD_GET_EXHIBITION_URL + '/' + id;
    Get(url, headers)
      .then(res => {
        const responseData = res.data.data;
        setExhibitionById(responseData);

        // Check if responseData is empty or null
        if (!responseData) {
          showErrorMessage('Data not found');
        }
      })
      .catch(error => {
        // Handle other errors if necessary
        // showErrorMessage("last catch message -- Data not found");
      });
  };

  useEffect(() => {
    getExhibitors(params.id, 1);
    getExhibitionById(params.id);
  }, []);

  return (
    <ExhibitiorContext.Provider
      value={{
        loadingExhibitors,
        setExhibitors,
        searchExhibitors,
        exhibitors,
        addBulkExhibitors,
        getExhibitors,
        addExhibitor,
        updateExhibitor,
        deleteExhibitor,
        exportExhibitorsData,
        exhibitorSearchData,
        exhibitorPage,
        selectedExhibitorPage,
        setSelectedExhibitorPage,
        currentTab,
        setCurrentTab,
        searchText,
        setSearchText,
        exhibitionById,
        resendEmail,
      }}>
      {children}
    </ExhibitiorContext.Provider>
  );
};

export default ExhibitorProvider;
