import React, { useContext } from 'react';
import { Route, Routes } from 'react-router';
import PagenotFound from '../components/PageNotFound';
import ConfirmPassword from '../pages/Confirm-password';
import Dashboard from '../pages/Dashboard';
import Exhibitionsdetails from '../pages/Exhibitions-details';
import Login from '../pages/Login';
import ResetPassword from '../pages/Reset-password';
import ScannedDataDetails from '../pages/ScannedData';
import ExhibitionProvider from '../services/context/exhibition/ExhibitionProvider';
import ExhibitorProvider from '../services/context/exhibitor/ExhibitorProvider';
import ScannedDataProvider from '../services/context/scannedData/ScannedDataProvider';
import { UserContext } from '../services/context/user/UserProvider';
import VisitorProvider from '../services/context/visitor/VisitorProvider';
import Splash from '../splash';

export default function Navigation() {
  const {tokendata, loading} = useContext(UserContext);

  const resetToken = window.location.pathname.replace('/forgotPassword/', '');

  let url = window.location.pathname.replace('/'+resetToken, '');

  if (loading) {
    return <Splash />;
  }

  if (tokendata) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <ExhibitionProvider>
              <Dashboard />
            </ExhibitionProvider>
          }
        />
        <Route
          path="/exhibitions-details/:id"
          element={
            <ExhibitorProvider>
              <VisitorProvider>
                <Exhibitionsdetails />
              </VisitorProvider>
            </ExhibitorProvider>
          }
        />
        <Route
          path="/scanned-data/:id"
          element={
            <ScannedDataProvider>
              <ScannedDataDetails />
            </ScannedDataProvider>
          }
        />
        <Route path="*" element={<PagenotFound />} />
      </Routes>
    );
  }

  if (url === '/forgotPassword') {
    return <ConfirmPassword resetToken={resetToken} />;
  }


  return (
    <Routes>
      <Route path="*" element={<PagenotFound />} />
      <Route path="/" element={<Login />} />
      <Route path="/sendEmailResetLink" element={<ResetPassword />} />
    </Routes>
  );
}
