import {EmailOutlined} from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, {useContext, useState} from 'react';
import {colors} from '../../lib/util/StringUtil';
import {VisitorContext} from '../../services/context/visitor/VisitorProvider';

const AddVisitorModal = ({open, handleClose, isEdit, data, id}) => {
  const visitorId = isEdit ? data.id : '';
  const [loader, setLoader] = useState(false);
  const {visitorPage, addVisitor, updateVisitor, getVisitors} =
    useContext(VisitorContext);

  const [visitorsData, setVisitorsData] = useState({
    name: isEdit ? data.name : '',
    email: isEdit ? data.email : '',
    mobileNumber: isEdit ? data.mobileNumber : '',
    companyName: isEdit ? data.companyName : '',
    designation: isEdit ? data.designation : '',
    website: isEdit ? data.website : '',
  });

  const [formErrors, setFormErrors] = useState({});

  // below function is for input handler for used text field
  const inputHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    setVisitorsData({...visitorsData, [name]: value});
    setFormErrors({});
  };

  // below function validate required fields
  const validateForm = () => {
    let errors = {};
    if (!visitorsData.name) {
      errors.name = 'Please enter a name';
    }
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!visitorsData.email) {
      errors.email = 'Please enter an email';
    } else if (!regex.test(visitorsData.email)) {
      errors.email = 'Please enter a valid email';
    }
    if (!visitorsData.mobileNumber) {
      errors.mobileNumber = 'Please enter a phone mobileNumber';
    } else if (visitorsData.mobileNumber.length !== 10) {
      errors.mobileNumber = 'Please enter a valid phone mobileNumber';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //this function is to submit data to the server
  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        return;
      }

      setLoader(true);

      const visitor = {
        name: visitorsData.name,
        email: visitorsData.email,
        mobileNumber: visitorsData.mobileNumber,
        companyName: visitorsData.companyName,
        designation: visitorsData.designation,
        website: visitorsData.website,
      };
      {
        isEdit
          ? await updateVisitor(visitor, visitorId, id)
          : await addVisitor(id, visitor);
      }
      await getVisitors();
      await visitorPage(1);
      handleClose();
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoader(false);
    }
  };

  const MAX_LENGTH = 10; 

  const handleInput = event => {
    const input = event.target.value.slice(0, MAX_LENGTH);
    event.target.value = input;
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {isEdit ? 'Update visitor' : 'Add new visitor'}
        </DialogTitle>
        <DialogContent>
          <Box className="createnewexhibition">
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Name"
                name="name"
                value={visitorsData.name}
                onChange={inputHandler}
                error={formErrors.name}
                helperText={formErrors.name}
              />
            </FormControl>

            <FormControl fullWidth variant="outlined">
              <TextField
                label="Company Name"
                name="companyName"
                value={visitorsData.companyName}
                onChange={inputHandler}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                name="designation"
                value={visitorsData.designation}
                onChange={inputHandler}
                label="Designation"
              />
            </FormControl>
          </Box>

          <Box className="createnewexhibition">
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Mobile Number"
                type="number"
                name="mobileNumber"
                onInput={handleInput}
                value={visitorsData.mobileNumber}
                onChange={inputHandler}
                error={formErrors.mobileNumber}
                helperText={formErrors.mobileNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <LocalPhoneOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Email"
                type="email"
                name="email"
                value={visitorsData.email}
                onChange={inputHandler}
                error={formErrors.email}
                helperText={formErrors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <EmailOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Website"
                type="url"
                name="website"
                value={visitorsData.website}
                onChange={inputHandler}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <LanguageIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              background: colors.white,
              border: `1px solid ${colors.secondaryColor}`,
              color: colors.secondaryColor,
              '&:hover': {
                background: colors.white,
              },
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{padding: '8px 35px'}}
            disabled={loader}>
            {loader ? (
              <CircularProgress
                size={24}
                style={{color: colors.primaryColor}}
              />
            ) : isEdit ? (
              'Update'
            ) : (
              'Add'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddVisitorModal;
