import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ActionsMenu from '../../components/ActionMenu';
import BoxPagination from '../../components/Pagination';
import QrModal from '../../components/QR';
import { colors } from '../../lib/util/StringUtil';
import { VisitorContext } from '../../services/context/visitor/VisitorProvider';
import { Visitorscolumns } from './data';

export default function Visitors({ id }) {
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { visitors, visitorPage, loadingVisitors, selectedVisitorPage } = useContext(VisitorContext);
  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    visitorPage(newPage);
  };

  // set data for custom pagination(Box pagination)
  const [visitorsData, setVisitorsData] = useState([]);
  const rowsPerPage = 50;
  const [visitorsDataLength, setVisitorsDataLength] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [pageCount, setPageCount] = useState(1);


  useEffect(() => {
    setVisitorsData(visitors?.visitors);
    setVisitorsDataLength(visitors?.totalItems);
    setTotalResults(visitors?.totalItems);
    const calculatedPageCount = Math.ceil(visitors?.totalItems / rowsPerPage) || 1;
    setPageCount(calculatedPageCount);
  }, [visitors?.currentPage, visitors?.totalItems]);

  const getRowCell = (column, value, row) => {
    const generateKey = () => `${row.id}-${column.id}`;
    switch (column.id) {
      case 'action':
        return (
          <TableCell key={generateKey()} align={column.align}>
            <ActionsMenu
              visitorData={row}
              visitorId={row.id}
              exhibitionId={id}
            />
          </TableCell>
        );
      default:
        return (
          <TableCell key={generateKey()} align={column.align}>
            {column.format && typeof value === 'number'
              ? column.format(value)
              : value ? value : 'NA'}
          </TableCell>
        );
    }
  };

  return (
    <Paper elevation={0} sx={{ overflow: 'hidden' }}>
      {loadingVisitors ? (
        <Box display="flex" justifyContent="center" minHeight="500px" alignItems={'center'}>
          <CircularProgress size={30} style={{ color: colors.primaryColor }} />
        </Box>
      ) : (
        <TableContainer className="tableboder visitortable">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {Visitorscolumns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {visitorsDataLength > 0 ? (
                visitorsData.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {Visitorscolumns.map((column, index) => {
                      const value = row[column.id];
                      const cellData = getRowCell(column, value, row);
                      return cellData;
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell >
                    <Typography>No Data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {visitorsDataLength > 50 && loadingVisitors === false && (
        <BoxPagination
          totalResults={totalResults}
          pageCount={pageCount}
          currentPage={selectedVisitorPage ? selectedVisitorPage : currentPage}
          onChangePage={handlePageChange}
          rowsPerPage={rowsPerPage}
        />
      )}
      <QrModal open={open} actions={handleClose} />
    </Paper>
  );
}