import {EmailOutlined} from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {colors} from '../../lib/util/StringUtil';
import {ExhibitiorContext} from '../../services/context/exhibitor/ExhibitorProvider';
const AddExhibitorModal = ({open, handleClose, data, isEdit, id}) => {
  const exhibitorId = isEdit ? data.id : '';

  const [loader, setLoader] = useState(false);
  const {
    exhibitorPage,
    addExhibitor,
    updateExhibitor,
    getExhibitors,
    resendEmail,
  } = useContext(ExhibitiorContext);

  const [exhibitorData, setExhibitorData] = useState({
    contactPerson: isEdit ? data.contactPerson : '',
    email: isEdit ? data.email : '',
    mobileNumber: isEdit ? data.mobileNumber : '',
    companyName: isEdit ? data.companyName : '',
    website: isEdit ? data.website : '',
  });

  const [formErrors, setFormErrors] = useState({});

  // below function is for input handler for used text field
  const inputHandler = e => {
    const name = e.target.name;
    const value = e.target.value;
    setExhibitorData({...exhibitorData, [name]: value});
    setFormErrors({});
  };

  // below function validate required fields
  const validateForm = () => {
    let errors = {};

    if (!exhibitorData.companyName) {
      errors.companyName = 'Please enter a company name';
    }
    if (!exhibitorData.contactPerson) {
      errors.contactPerson = 'Please enter a contact person';
    }
    if (!exhibitorData.mobileNumber) {
      errors.mobileNumber = 'Please enter a phone mobileNumber';
    } else if (exhibitorData.mobileNumber.length !== 10) {
      errors.mobileNumber = 'Please enter a valid phone mobileNumber';
    }
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!exhibitorData.email) {
      errors.email = 'Please enter an email address';
    } else if (!regex.test(exhibitorData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // const handleResendEmail = async (newData) => {
  //   if (exhibitorData.email !== data.email) {
  //     await resendEmail(exhibitorId);
  //   getExhibitors(newData, exhibitorId, id);

  //   }
  // }
  const handleSubmit = async () => {
    try {
      if (!validateForm()) {
        return;
      }
      setLoader(true);
      let newData = {
        contactPerson: exhibitorData.contactPerson,
        email: exhibitorData.email,
        mobileNumber: exhibitorData.mobileNumber,
        companyName: exhibitorData.companyName,
        website: exhibitorData.website,
      };

      if (isEdit) {
          await updateExhibitor(newData, exhibitorId, id);
          getExhibitors(newData, exhibitorId, id);

        if (exhibitorData.email !== data.email) {
          await resendEmail(exhibitorId);
        }
        getExhibitors(newData, exhibitorId, id);
      } else {
        await addExhibitor(id, newData);
      }

      setTimeout(() => {
        setLoader(false);
        handleClose();
      }, 200);

      await exhibitorPage(1);
      handleClose();
    } catch (error) {
      setLoader(false);
    }
  };

  

  const MAX_LENGTH = 10;

  const handleInput = event => {
    const input = event.target.value.slice(0, MAX_LENGTH);
    event.target.value = input;
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">
          {isEdit ? 'Update exhibitor' : 'Add new exhibitor'}
        </DialogTitle>
        <DialogContent>
          <Box className="createnewexhibition">
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Company Name"
                name="companyName"
                value={exhibitorData.companyName}
                onChange={inputHandler}
                error={formErrors.companyName}
                helperText={formErrors.companyName}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                name="contactPerson"
                value={exhibitorData.contactPerson}
                onChange={inputHandler}
                label="Contact Person"
                error={formErrors.contactPerson}
                helperText={formErrors.contactPerson}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <PersonOutlineOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>

          <Box className="createnewexhibition">
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Mobile Number"
                type="number"
                name="mobileNumber"
                onInput={handleInput}
                value={exhibitorData.mobileNumber}
                onChange={inputHandler}
                error={formErrors.mobileNumber}
                helperText={formErrors.mobileNumber}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <LocalPhoneOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Email"
                type="email"
                name="email"
                value={exhibitorData.email}
                onChange={inputHandler}
                error={formErrors.email}
                helperText={formErrors.email}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <EmailOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth variant="outlined">
              <TextField
                label="Website"
                type="url"
                name="website"
                value={exhibitorData.website}
                onChange={inputHandler}
                error={formErrors.website}
                helperText={formErrors.website}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <LanguageIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              background: colors.white,
              border: `1px solid ${colors.secondaryColor}`,
              color: colors.secondaryColor,
              '&:hover': {
                background: colors.white,
              },
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmit(exhibitorData)}
            sx={{padding: '8px 35px'}}
            disabled={loader}>
            {loader ? (
              <CircularProgress
                size={24}
                style={{color: colors.primaryColor}}
              />
            ) : isEdit ? (
              'Update'
            ) : (
              'Add'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddExhibitorModal;
