import CircularProgress from '@material-ui/core/CircularProgress';
import {useContext, useState} from 'react';
import {colors} from '../../lib/util/StringUtil';
import {ScannedDataContext} from '../../services/context/scannedData/ScannedDataProvider';
import {Button} from '@mui/material';

export default function Export({exhibitorId}) {
  const [loading, setLoading] = useState(false);
  const {exportScannedData, scannedData} = useContext(ScannedDataContext);
  const handleExport = async () => {
    setLoading(true);
    await exportScannedData(exhibitorId);
    setLoading(false);
  };
  return (
    <div>
      {scannedData?.length > 0 ? (
        <Button
          style={{width: 100}}
          variant="contained"
          onClick={handleExport}
          disabled={loading}>
          {loading ? (
            <CircularProgress size={24} style={{color: colors.primaryColor}} />
          ) : (
            'Export'
          )}
        </Button>
      ) : null}
    </div>
  );
}
