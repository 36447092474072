export const CAPLEAD_LOGIN_URL = '/superAdminLogin';
export const CAPLEAD_ADD_EXHIBITION_URL = '/addExhibition';
export const CAPLEAD_GET_EXHIBITION_URL = '/getExhibitions';
export const CAPLEAD_UPDATE_EXHIBITION_URL = '/updateExhibition';
export const CAPLEAD_ADD_BULK_EXHIBITORS_URL = '/addBulkExhibitors';
export const CAPLEAD_ADD_BULK_VISITORS_URL = '/addBulkVisitors';
export const CAPLEAD_ADD_EXHIBITORS_URL = '/addExhibitor';
export const CAPLEAD_ADD_VISITORS_URL = '/addVisitor';
export const CAPLEAD_GET_SCANNED_DATA = '/getExhibitorScannedData';
export const CAPLEAD_EXPORT_SCANNED_DATA = '/exportExhibitorScannedData'; 
export const CAPLEAD_GET_VISITORS_URL = '/getExhibitionVisitors';
export const CAPLEAD_GET_EXHIBITORS_URL = '/getExhibitionExhibitors';
export const CAPLEAD_UPDATE_EXHIBITOR_URL = '/updateExhibitor';
export const CAPLEAD_UPDATE_VISITOR_URL = '/updateVisitor';
export const CAPLEAD_DELETE_EXHIBITOR_URL = '/deleteExhibitor';
export const CAPLEAD_DELETE_VISITOR_URL = '/deleteVisitor';
export const CAPLEAD_LOGOUT_URL = '/logout';
export const CAPLEAD_EXPORT_EXHIBITORS_URL = '/exportExhibitors';
export const CAPLEAD_EXPORT_VISITORS_URL = '/exportVisitors';
export const CAPLEAD_EXHIBITION_SEARCH_URL = '/searchExhibitions';
export const  CAPLEAD_EXHIBITOR_SEARCH_URL = '/searchExhibitors';
export const CAPLEAD_VISITOR_SEARCH_URL = '/searchVisitors';
export const CAPLEAD_SEACRH_SCANNED_DATA_URL = '/searchScannedData'; 
export const CAPLEAD_ORGANIZER_LOGIN_URL = '/organizerLogin';
export const CAPLEAD_RESET_PASSWORD_URL = '/forgotPassword';
export const CAPLEAD_RESET_PASSWORD_LINK_GENERATE_URL = '/forgotOrganizerPassword';
export const CAPLEAD_RESEND_EMAIL_EXHIBITOR_URL = '/resendEmailExhibitor';