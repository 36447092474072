import { TextField } from '@mui/material';
import React, { Fragment } from 'react';

export default function Searchbar({onChange, searchText}) {
  return (
    <Fragment>
      <TextField
        variant="outlined"
        onChange={onChange}
        placeholder="Search…"
        value={searchText ? searchText : ''}
      />
    </Fragment>
  );
}
