import {createTheme} from '@mui/material/styles';
import {colors} from '../lib/util/StringUtil';
export const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.white,
          padding: '16px 20px',
          boxShadow: '0px 5px 10px rgba(32, 38, 36, 0.05)',
          position: 'sticky',
          top: '0',
          zIndex: 99,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: 'initial!important',
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif, Arial',
          color: colors.secondaryColor,
          letterSpacing: '0',
        },
      },
      defaultProps: {
        fontFamily: 'Lato, sans-serif, Arial',
        letterSpacing: '0',
        color: colors.secondaryColor,
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: colors.secondaryColor,
          background: colors.white,
          '& .MuiBreadcrumbs-ol': {
            border: `1px solid ${colors.primary_light}`,
            borderRadius: '5px',
            padding: '8px 16px',
            lineHeight: 'initial',
          },
          '& .MuiBreadcrumbs-li a': {
            color: colors.light_grey,
            textDecoration: 'none',
          },
          '& .MuiBreadcrumbs-li a svg': {
            fill: colors.light_grey,
          },
          '& .MuiBreadcrumbs-separator svg': {
            fill: colors.light_grey,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: colors.secondaryColor,
          background: colors.white,
          fontFamily: 'Lato, sans-serif, Arial',
          borderWidth: 1,
          borderColor: colors.light_grey,
          fontWeight: 500,
          padding: '0 15px',
          fontSize: '16px',
          height: 56,
          letterSpacing: 0,
          boxShadow: 'none',
          '& .MuiOutlinedInput-input': {
            padding: '0',
            height: 'auto',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
            borderColor: colors.light_grey,
          },
          '&:focus': {
            border: `2px solid ${colors.primaryColor}`,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.primaryColor}`,
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontFamily: 'Lato, sans-serif, Arial',
          fontSize: 16,
          fontWeight: '400',
          color: colors.light_grey,
          marginBottom: 10,
          '&:focus': {
            color: colors.primaryColor,
          },
          '&.Mui-focused': {
            color: colors.primaryColor,
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif, Arial',
          fontSize: 16,
          fontWeight: '400',
          '&:focus': {
            borderWidth: 2,
            borderColor: colors.primaryColor,
          },
          '&.Mui-focused': {
            borderWidth: 2,
            borderColor: colors.primaryColor,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primaryColor,
          fontSize: 16,
          borderRadius: '4px',
          fontWeight: 700,
          textTransform: 'capitalize',
          padding: '8px 22px',
          lineHeight: '24px',
          letterSpacing: 0,
          boxShadow: 'none',
          fontFamily: 'Lato, sans-serif, Arial',
          '&:hover': {
            background: colors.primaryColor,
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 20,
          display: 'flex',
          flexDirection: 'column',
          gap: 15,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: colors.white,
          boxShadow: '-5px 10px 20px rgba(0, 0, 0, 0.05)',
          borderRadius: 10,
          flexBasis: 'calc(33.333% - 14px)',
          textDecoration: 'none',
          position: 'relative',
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 20,
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          width: 40,
          height: 40,
          fontSize: 16,
          fontFamily: 'Lato, sans-serif, Arial',
          borderRadius: 50,
          letterSpacing: 0,
          lineHeight: '24px',
          color: colors.secondaryColor,
          padding: 0,
          margin: '0 6px',
          '&.Mui-selected:hover': {
            background: colors.primaryColor,
          },
          '&.Mui-selected': {
            background: colors.primaryColor,
            color: colors.white,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: colors.primaryColor,
          fontSize: 16,
          textDecoration: 'none',
          fontWeight: 400,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: colors.primaryColor,
          height: 2,
        },
        root: {
          minHeight: 'initial',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          fontFamily: 'Lato, sans-serif, Arial',
          color: colors.light_grey,
          fontWeight: 700,
          fontSize: 16,
          lineHeight: '24px',
          maxWidth: 'initial',
          minHeight: 'initial',
          minWidth: 'initial',
          letterSpacing: 0,
          padding: '20px',
          flexDirection: 'row',
          gap: 10,
          '&.Mui-selected': {
            color: colors.primaryColor,
          },
          '& .MuiSvgIcon-root': {
            margin: 0,
          },
          '&.MuiTypography-root': {
            padding: 0,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: '500',
          fontSize: 14,
          lineHeight: '17px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 16,
          padding: '12px 5px',
          borderBottom: 'none',
          letterSpacing: 0,
          fontFamily: 'Lato, sans-serif, Arial',
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
          '&.MuiTableCell-head': {
            fontSize: 18,
            textTransform: 'capitalize',
          },
          '& .MuiButtonBase-root': {
            padding: 0,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            fontSize: 12,
            background: colors.grey_800,
            fontWeight: 700,
            lineHeight: '15px',
            textTransform: 'uppercase',
            color: colors.secondaryColor,
            fontFamily: 'Lato, sans-serif, Arial',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif, Arial',
          '& .MuiTablePagination-displayedRows': {
            fontFamily: 'Lato, sans-serif, Arial',
          },
          '& .MuiTablePagination-actions': {
            '& button:hover': {
              color: colors.primaryColor,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            fontFamily: 'Lato, sans-serif, Arial',
            margin: 0,
            borderRadius: 10,
            '&.MuiPaper-root': {
              width: 790,
              maxWidth: 'initial',
            },
            '& .MuiTypography-h6': {
              padding: 20,
              borderBottom: `solid 1px ${colors.primary_light}`,
              fontWeight: 700,
              fontSize: 22,
              lineHeight: '33px',
            },
            '& .MuiDialogContent-root': {
              marginTop: 20,
              padding: '0 20px',
              overflowY: 'initial',
            },
            '& .MuiStack-root': {
              marginBottom: 0,
              width: '100%',
              padding: 0,
              overflow: 'initial',
            },
            '& .MuiFormControl-root': {
              width: '100%',
            },
            '& .MuiDialogActions-root': {
              padding: 20,
            },
          },
        },
      },
    },
  },

  typography: {
    h1: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: '30px',
      padding: 20,
      borderBottom: `solid 1px ${colors.primary_light}`,
      display: 'flex',
      alignItems: 'center',
    },
    h2: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '33px',
    },
    subtitle1: {
      fontWeight: 600,
      color: colors.secondaryColor,
      marginBottom: 10,
      fontSize: 18,
      lineHeight: '22px',
    },
    body1: {
      fontWeight: 700,
    },
  },
});
