import HomeIcon from '@mui/icons-material/Home';
import { Breadcrumbs, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { CanAccessToCreatExhibition } from '../../accessManager';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import CreateExhibitions from '../../components/CreateExhibitions';
import ExhibitionsCards from '../../components/ExhibitionsCards';
import Header from '../../components/Header';
import Searchbar from '../../components/Searchbar';
import { ExhibitionContext } from '../../services/context/exhibition/ExhibitionProvider';
import './style.css';

export default function Dashboard() {
  const [openmodal, setOpenmodal] = useState(false);

  const handleClickOpen = () => {
    setOpenmodal(true);
  };
  const handleClose = () => {
    setOpenmodal(false);
  };

  return (
    <Fragment>
      <Header />
      <AlertMessage />
      <div className="container">
        <Box className="breadcrumbs">
          <Breadcrumbs>
            <HomeIcon />
          </Breadcrumbs>
          <Box className="createingbar">
            <ExhibitionSearch />
            {CanAccessToCreatExhibition() && ( 
              <Button variant="contained" onClick={handleClickOpen}>
                Create
              </Button>
            )}
            {/*
            <Box className="information">
              <NotificationsNoneIcon />
              <InfoOutlinedIcon />
            </Box>*/}
          </Box>
        </Box>
        <ExhibitionsCards />
        {openmodal && (
          <CreateExhibitions actionclose={handleClose} openmodal={openmodal} />
        )}
      </div>
    </Fragment>
  );
}

const ExhibitionSearch = () => {
  const { searchExhibition } = useContext(ExhibitionContext);
  const [searchText, setSearchText] = useState('');

  const callSearchApi = () => {
    searchExhibition(searchText);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      callSearchApi(searchText);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText]);

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
  };

  return (
    <Fragment>
      <Searchbar onChange={handleInputChange} searchText={searchText} />
    </Fragment>
  );
};
