import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Skeleton,
  TextField,
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import Logo from '../../icons/logo';
import { colors } from '../../lib/util/StringUtil';
import { UserContext } from '../../services/context/user/UserProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export default function ConfirmPassword({resetToken}) {
  
  const {resetPassword} = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loginData, setLoginData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    password: '',
    confirmPassword: '',
  });

  const [showSkeleton, setShowSkeleton] = useState(false);

  const clearError = () => {
    setError({password: '', confirmPassword: ''});
  };

  const onClickResetPassword = async event => {
    event.preventDefault();
    const {password, confirmPassword} = loginData;

    if (password !== confirmPassword) {
      setError(prevError => ({
        ...prevError,
        confirmPassword: 'Passwords do not match',
      }));
    } else {
      setLoader(true);
      await resetPassword(password, confirmPassword, resetToken);
      setLoader(false);
      setTimeout(() => {
        setLoader(false);
        window.location.href = '/';
      },3000)
    }
    setLoader(false);
  };

  const inputHandler = evt => {
    clearError();
    const {name, value} = evt.target;
    setLoginData(prevLoginData => ({
      ...prevLoginData,
      [name]: value,
    }));
  };

  return (
    <Fragment>
      <div className="container">
        <form onSubmit={onClickResetPassword}>
          <Box className="loginscreen">
            <Paper elevation={0} className="loginbox" variant="outlined">
              <Logo />
              <strong>Please enter new password</strong>

              {showSkeleton ? (
                <div className="skeletonloading">
                  <Skeleton />
                  <Skeleton />
                </div>
              ) : (
                <div className="loginloading">
                  <TextField
                    fullWidth
                    label="New password"
                    variant="outlined"
                    name="password"
                    value={loginData.password}
                    onChange={inputHandler}
                    error={Boolean(error.password)}
                    helperText={error.password}
                    autoComplete="New password"
                  />

                  <TextField
                    fullWidth
                    label="Confirm password"
                    variant="outlined"
                    name="confirmPassword"
                    type={ showPassword ? 'text' : 'password' }
                    value={loginData.confirmPassword}
                    onChange={inputHandler}
                    error={Boolean(error.confirmPassword)}
                    helperText={error.confirmPassword}
                    autoComplete="Confirm password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{
                              color: colors.primaryColor,
                              margin: '0px 0px 0px 10px',
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end">
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              )}
              <Button
                fullWidth
                type="submit"
                className="loginbutton"
                variant="contained"
                disabled={loader}
                onClick={onClickResetPassword}>
                {loader ? (
                  <CircularProgress
                    size={28}
                    style={{color: colors.primaryColor}}
                  />
                ) : (
                  'Submit'
                )}
              </Button>
              <AlertMessage />
            </Paper>
          </Box>
        </form>
      </div>
    </Fragment>
  );
}
