import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, {Fragment} from 'react';
import {colors} from '../../lib/util/StringUtil';

const ViewExhibitorModal = ({viewModalopen, handleCloseviewModal, data}) => {
  const {companyName, contactPerson, mobileNumber, email, website} = data;
  return (
    <Dialog open={viewModalopen} onClose={handleCloseviewModal}>
      <DialogTitle id="alert-dialog-title">View Exhibitor Details</DialogTitle>
      <DialogContent>
        <Fragment>
          <Box className="createnewexhibition">
            <Box>
              <label>Company Name</label>
              <Typography variant="body1">{companyName}</Typography>
            </Box>

            <Box>
              <label>Contact Person</label>
              <Typography variant="body1">{contactPerson}</Typography>
            </Box>
            <Box></Box>
          </Box>

          <Box className="createnewexhibition">
            <Box>
              <label>Number</label>
              <Typography variant="body1">{mobileNumber}</Typography>
            </Box>

            <Box>
              <label>Email</label>
              <Typography variant="body1">{email}</Typography>
            </Box>

            <Box>
              <label>Website</label>
              <Typography variant="body1">{website}</Typography>
            </Box>
          </Box>
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseviewModal}
          sx={{
            background: colors.white,
            border: `1px solid ${colors.secondaryColor}`,
            color: colors.secondaryColor,
            '&:hover': {
              background: colors.white,
            },
          }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewExhibitorModal;
