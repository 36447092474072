import React from 'react';
import { PaginationItem, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function BoxPagination({ pageCount, currentPage, onChangePage, totalResults, rowsPerPage }) {

  const handlePageChange = (event, value) => {
    onChangePage(value);
  };
  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === pageCount;

  const startIndex = (currentPage - 1) * rowsPerPage + 1;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalResults);

  return (
    <div>
      <Stack>
        <Typography variant="caption" >
          Showing {startIndex}-{endIndex} of {totalResults}
        </Typography>
        <div>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                disabled={(isFirstPage && item.type === 'previous') || (isLastPage && item.type === 'next')}
              />
            )}
          />
        </div>
      </Stack>
    </div>
  );
}
