
export const Exhibitorcolumns = [
  // { id: 'checkbox', label: <Checkbox /> },
  { id: 'companyName', label: 'Company name' },
  { id: 'contactPerson', label: 'Contact person', minWidth: 200 },
  {
    id: 'mobileNumber',
    label: 'Phone number',
    minWidth: 200,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 200,
  },
  {
    id: 'accessCode',
    label: 'Access Code',
  },
  // will be added later
  // {
  //   id: 'resendMail',
  //   label: 'Resend Mail',
  // },
  {
    id: 'action',
    minWidth: 20,
    align: 'right',
  },
];


export const Visitorscolumns = [
  { id: 'name', label: 'Name' },
  { id: 'companyName', label: 'Company name' },
  { id: 'designation', label: 'Designation', minWidth: 200 },

  {
    id: 'mobileNumber',
    label: 'Phone number',
    minWidth: 200,
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 200,
  },

  {
    id: 'action',
    minWidth: 20,
    align: 'right',
  },
];


