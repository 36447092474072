import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  AppBar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Logo from '../../icons/logo';
import {UserContext} from '../../services/context/user/UserProvider';
import AlertDialog from './logout';
import './style.css';

export default function Header() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [open, setOpen] = useState(false);
  const {username} = useContext(UserContext);
  const navigate = useNavigate();
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDialog = () => {
    setOpen(true);
  };

  const handleDropDown = dropDown => {
    switch (dropDown) {
      case 'Profile':
        navigate('/profile');
        break;
      case 'Account':
        navigate('/account');
        break;
      case 'Dashboard':
        navigate('/');
        break;
      case 'Log Out':
        handleDialog();
        break;
      default:
        break;
    }
    handleCloseUserMenu();
  };

  const name = username;
  const avtarname = name
    .split(' ')
    .map(n => n[0])
    .join('');

  return (
    <AppBar position="static" className="header">
      <div className="container">
        <Toolbar disableGutters>
          <Box className="menuleftside">
            {/*} <IconButton disableRipple className="menubutton">
              {/* <MenuIcon /> 
            </IconButton>*/}
            <Box className="logo">
              <Logo />
            </Box>
          </Box>

          <Box className="usermenu">
            <Avatar>{avtarname}</Avatar>
            <IconButton disableRipple onClick={handleOpenUserMenu} sx={{p: 0}}>
              <Typography variant="body1">
                {name} <ArrowDropDownIcon />
              </Typography>
            </IconButton>
            <Menu
              sx={{mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {['Dashboard', 'Log Out'].map(dropDown => (
                <MenuItem
                  key={dropDown}
                  onClick={() => handleDropDown(dropDown)}>
                  <Typography textAlign="center">{dropDown}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <AlertDialog open={open} close={() => setOpen(false)} />
      </div>
    </AppBar>
  );
}
