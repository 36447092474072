import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { Fragment, useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Export from '../../components/ExportButton';
import Header from '../../components/Header';
import Searchbar from '../../components/Searchbar';
import { ScannedDataContext } from '../../services/context/scannedData/ScannedDataProvider';
import ScannedData from './scannedData';
import './style.css';

export default function ScannedDataDetails() {
  const { state } = useLocation();
  const exhibitorData = state.exhibitorData ? state.exhibitorData : '';
  const exhibitionData = state.exhibitionData ? state.exhibitionData : '';
  return (
      <Fragment>
        <Header />
        <div className="container">
          <Box className="breadcrumbs">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <Link key="dashboard" to="/">
                <HomeIcon />
              </Link>
              <Link key="2" to={`/exhibitions-details/${exhibitionData.id}`} state={exhibitionData}>
                {exhibitionData.name ? exhibitionData.name : 'Exhibitions'}
              </Link>
              <Typography>{exhibitorData.companyName ? exhibitorData.companyName : 'Company Name'}</Typography>
            </Breadcrumbs>

            <Box className="createingbar">
              <SearchScannedData exhibitorId={exhibitorData.id}  />
              <Export exhibitorId={exhibitorData.id} />
              {/*
            <Box className="information">
              <NotificationsNoneIcon />
              <InfoOutlinedIcon />
            </Box>*/}
            </Box>
          </Box>
          <ScannedData exhibitorId={exhibitorData.id }  />
        </div>
      </Fragment>
  );
}

const SearchScannedData = ({exhibitorId}) => { 
  const {searchScannedData,  searchText, setSearchText} = useContext(ScannedDataContext);
  
  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchScannedData(exhibitorId,searchText);
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchText])
  return (
      <Searchbar onChange={handleInputChange} searchText={searchText} />
  );
}
