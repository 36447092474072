export const scannedColumns = [
  { id: 'name', label: 'Person name' },

  { id: 'companyName', label: 'Company name' },
  { id: 'designation', label: 'designation' },
  {
    id: 'mobileNumber',
    label: 'Number',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'note',
    label: 'Comment',
  },

  {
    id: 'scannedBy',
    label: 'Scanned By',
    minWidth: 50,

  },
];

