import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CAPLEAD_ADD_BULK_VISITORS_URL,
  CAPLEAD_ADD_VISITORS_URL,
  CAPLEAD_DELETE_VISITOR_URL,
  CAPLEAD_EXPORT_VISITORS_URL,
  CAPLEAD_GET_VISITORS_URL,
  CAPLEAD_UPDATE_VISITOR_URL,
} from '../../api';
import {Delete, Get, GetExport, Post, Update} from '../../axios';
import {UserContext} from '../user/UserProvider';
import {CAPLEAD_VISITOR_SEARCH_URL} from '../../api';
import {useParams} from 'react-router-dom';
import {AlertMessageContext} from '../aletMessage/AlertMessageProvider';
import {ExhibitiorContext} from '../exhibitor/ExhibitorProvider';

export const VisitorContext = createContext();
const VisitorProvider = ({children}) => {
  const {tokendata} = useContext(UserContext);
  const [visitors, setVisitors] = useState({});
  const [visitorSearchData, setVisitorSearchData] = useState([]);
  const [loadingVisitors, setLoadingVisitors] = useState(true);
  const [selectedVisitorPage, setSelectedVisitorPage] = useState(1);
  const {showErrorMessage, showSuccessMessage} =
    useContext(AlertMessageContext);
  const {searchText} = useContext(ExhibitiorContext);
  const params = useParams();

  // this function is add bulk data of visitors for specific exhibition by exhibitionId
  const addBulkVisitors = async (exhibitionId, file) => {
    const headers = {'x-access-token': tokendata};

    const formData = new FormData();
    formData.append('file', file);
    formData.append('exhibitionId', exhibitionId);
    try {
      const response = await Post(
        CAPLEAD_ADD_BULK_VISITORS_URL,
        formData,
        headers,
      );
      showSuccessMessage('Bulk Visitors added successfully');
      return response.data;
    } catch (error) {
      showErrorMessage('Bulk Visitors not added');
    }
  };

  const addVisitor = async (exhibitionId, data) => {
    const headers = {'x-access-token': tokendata};
    const formData = {
      exhibitionId: exhibitionId,
      ...data,
    };
    try {
      const response = await Post(CAPLEAD_ADD_VISITORS_URL, formData, headers);
      if (response.status.code === 201) {
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
      return response.data;
    } catch (error) {
      showErrorMessage('Visitor added api');
    }
  };

  // this function is get visitors for specific exhibition by exhibitionId
  const getVisitors = useCallback(async (exhibitionId, currentPage) => {
    try {
      const headers = {'x-access-token': tokendata};
      const url =
        CAPLEAD_GET_VISITORS_URL + '/' + exhibitionId + '?page=' + currentPage; 
      await Get(url, headers)
        .then(response => {
          setVisitors(response?.data?.data);
          setSelectedVisitorPage(currentPage);
          setVisitorSearchData(response.data.data);
        })
        .finally(() => {
          setTimeout(() => {
            setLoadingVisitors(false);
          }, 1000);
        });
    } catch (error) {
      showErrorMessage('Visitors not found');
    }
  }, []);

  const visitorPage = async currentPage => {
    if (searchText) {
      await searchVisitors(params.id, currentPage, searchText);
    } else {
      await getVisitors(params.id, currentPage);
    }
  };
  const updateVisitor = async (data, visitorId) => {
    try {
      const headers = {'x-access-token': tokendata};
      const url = CAPLEAD_UPDATE_VISITOR_URL + '/' + visitorId;
      const response = await Update(url, data, headers);
      if (response.data.status.code === 200) {
        showSuccessMessage(response.data.status.message);
      } else {
        showErrorMessage(response.data.status.message);
      }
      return response;
    } catch (error) {
      showErrorMessage('Visitor update api error');
    }
  };

  const deleteVisitor = async (visitorId, exhibitionId) => {
    try {
      const headers = {'x-access-token': tokendata};
      const url =
        CAPLEAD_DELETE_VISITOR_URL +
        '/' +
        visitorId +
        '?exhibitionId=' +
        exhibitionId;
      await Delete(url, headers);
      updateVisitorData(visitorId);
      showSuccessMessage('Visitor deleted successfully');
    } catch (error) {
      showErrorMessage('Visitor not deleted');
    }
  };

  // this function is update visitors data locally after delete exhibitor by exhibitorId
  const updateVisitorData = async visitorId => {
    const removeDeletedVisitor = visitors?.visitors.filter(
      visitor => visitor.id !== visitorId,
    );
    let data = {
      currentPage: visitors.currentPage,
      visitors: removeDeletedVisitor,
      totalItems: visitors.totalItems - 1,
      totalPages: visitors.totalPages,
    };
    setVisitors(data);
  };

  const searchVisitors = async (exhibitionId, currentPage = 1, data) => {
    if (data === '') {
      getVisitors(params.id, 1);
    } else {
      const headers = {'x-access-token': tokendata};
      const url =
        CAPLEAD_VISITOR_SEARCH_URL +
        '?exhibitionId=' +
        exhibitionId +
        '&searchTerm=' +
        data +
        '&page=' +
        currentPage;
      Get(url, headers)
        .then(res => {
          setVisitors(res.data.data);
          setSelectedVisitorPage(res.data.data.currentPage);
        })
        .catch(error => {
          showErrorMessage('Search Visitors not found');
        });
    }
  };

  const exportVisitorsData = async id => {
    try {
      const headers = {
        'x-access-token': tokendata,
      };
      const url = CAPLEAD_EXPORT_VISITORS_URL + '/' + id;
      const response = await GetExport(url, headers);
      const mainblob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const mainurl = URL.createObjectURL(mainblob);
      const disposition = response.headers['content-disposition'];
      const filename =
        disposition?.match(/filename=([^;]+)/i)?.[1]?.trim() || 'my-file.xlsx'; 
      const link = document.createElement('a');
      link.href = mainurl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      showSuccessMessage('Visitors exported successfully');
    } catch (error) {
      showErrorMessage('Visitors not exported');
    }
  };

  useEffect(() => {
    getVisitors(params.id, 1);
  }, []);
  return (
    <VisitorContext.Provider
      value={{
        addBulkVisitors,
        visitors,
        searchVisitors,
        visitorSearchData,
        getVisitors,
        addVisitor,
        deleteVisitor,
        updateVisitor,
        exportVisitorsData,
        selectedVisitorPage,
        visitorPage,
        loadingVisitors,
      }}>
      {children}
    </VisitorContext.Provider>
  );
};
export default VisitorProvider;
