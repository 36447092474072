import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import {colors} from '../../lib/util/StringUtil';
export default function QrModal({open, actions}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={actions}
        PaperProps={{
          style: {
            width: '350px',
          },
        }}>
        <DialogTitle sx={styles.dialogtitle}>
          {'QR Code of Hope Hauck'}
          <IconButton onClick={actions} sx={styles.closeicon}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CardMedia component="img" image="images/qr.svg" alt="qr code" />
        </DialogContent>
        <DialogActions>
          <Typography style={{fontWeight: 400}}>
            QR codes provide a convenient and efficient way for exhibitors to
            access visitor’s information at the exhibition.
          </Typography>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = {
  dialogtitle: {
    borderBottom: 'none!important',
    paddingBottom: '0!important',
    fontSize: '16px!important',
    color: colors.primaryColor,
    lineHeight: '25px!important',
  },
  closeicon: {
    padding: 0,
    float: 'right',
  },
};
