import { MoreVertOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { Fragment, useContext, useState } from 'react';
import DeleteModal from './delete';
import ViewExhibitorModal from './viewExhibitor';
import ViewVisitorModal from './viewVisitor';
import AddExhibitorModal from '../InnerHeader/addExhibitor';
import AddVisitorModal from '../InnerHeader/addVisitor';
import { ExhibitiorContext } from '../../services/context/exhibitor/ExhibitorProvider';

export default function ActionsMenu({
  exhibitorId,
  visitorId,
  exhibitionId,
  exhibitorData,
  visitorData,
}) {
  const { currentTab } = useContext(ExhibitiorContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickdeletemodal = () => {
    setShow(true);
    handleClose();
  };

  // view modal function
  const [viewModalopen, setViewModalopen] = useState(false);
  const handleClickOpenviewModal = () => {
    setViewModalopen(true);
  };
  const handleCloseviewModal = () => {
    setViewModalopen(false);
  };

  const Viewmodalclose = () => {
    setViewModalopen(true);
    handleClose();
  };

  // edit modal function
  const [editModalopen, setEditModalopen] = useState(false);

  const editmodalClose = () => {
    setEditModalopen(true);
    handleClose();
  };


  return (
    <Fragment>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={Viewmodalclose}>View</MenuItem>
        <MenuItem onClick={editmodalClose}>Edit</MenuItem>
        <MenuItem onClick={onClickdeletemodal}>Delete</MenuItem>
      </Menu>

      {currentTab === 0 ? (
        <ViewExhibitorModal
          viewModalopen={viewModalopen}
          handleCloseviewModal={handleCloseviewModal}
          handleClickOpenviewModal={handleClickOpenviewModal}
          data={exhibitorData}
        />
      ) : (
        <ViewVisitorModal
          viewModalopen={viewModalopen}
          handleCloseviewModal={handleCloseviewModal}
          handleClickOpenviewModal={handleClickOpenviewModal}
          data={visitorData}
        />
      )}

      <DeleteModal
        show={show}
        close={() => setShow(false)}
        exhibitorId={exhibitorId}
        visitorId={visitorId}
        exhibitionId={exhibitionId}
      />
      {currentTab === 0 ? (
        <AddExhibitorModal
          open={editModalopen}
          handleClose={() => setEditModalopen(false)}
          isEdit={true}
          id={exhibitionId}
          data={exhibitorData}
        />
      ) : (
        <AddVisitorModal
          open={editModalopen}
          handleClose={() => setEditModalopen(false)}
          isEdit={true}
          data={visitorData}
          exhibitionId={exhibitionId}
          visitorId={visitorId}
        />
      )}
    </Fragment>
  );
}
