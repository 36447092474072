import { Button, CircularProgress } from '@mui/material';
import React, { useContext, useState } from 'react'
import { ExhibitiorContext } from '../../services/context/exhibitor/ExhibitorProvider';
import { VisitorContext } from '../../services/context/visitor/VisitorProvider';
import { colors } from '../../lib/util/StringUtil';

const ExportFile = ({ value, id, name }) => {
    const { exportExhibitorsData, exhibitors } = useContext(ExhibitiorContext);
    const { exportVisitorsData, visitors } = useContext(VisitorContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleExport = () => {
        setIsLoading(true);
        if (value === 0) {
            exportExhibitorsData(id, name).finally(() => setIsLoading(false));
        }
        else if (value === 1) {
            exportVisitorsData(id, name).finally(() => setIsLoading(false)); 
        }
    }

    const shouldDisplayButton = exhibitors?.exhibitors?.length > 0 || visitors?.visitors?.length > 0;
    const shouldDisableButton = (value === 0 && exhibitors?.exhibitors?.length === 0) || (value === 1 && visitors?.visitors?.length === 0);

    return (
        <div>

            {shouldDisplayButton ? <Button style={{ width: 100 }} variant="contained" onClick={handleExport} disabled={shouldDisableButton || isLoading}>
                {isLoading ? <CircularProgress size={24} style={{ color: colors.primaryColor }} /> : 'Export'}
            </Button> : null}

        </div>
    )
}




export default ExportFile