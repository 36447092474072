import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useContext, useState } from 'react';
import { colors } from '../../lib/util/StringUtil';
import { ExhibitiorContext } from '../../services/context/exhibitor/ExhibitorProvider';
import { VisitorContext } from '../../services/context/visitor/VisitorProvider';

export default function DeleteModal({
  show,
  close,
  visitorId,
  exhibitorId,
  exhibitionId,
}) {
  const { deleteExhibitor, currentTab } = useContext(ExhibitiorContext);
  const { deleteVisitor } = useContext(VisitorContext);
  const [loader, setLoader] = useState(false);
  const handleDelete = async () => {

    setLoader(true);
    if (currentTab === 0) {
      await deleteExhibitor(exhibitorId);
    } else {
      await deleteVisitor(visitorId, exhibitionId);
      setLoader(false);
      close();
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '500px',
        },
      }}
      open={show}>
      <DialogTitle>
        {currentTab === 0 ? 'Are you sure you want to delete exhibitor ?' : 'Are you sure you want to delete visitor ?'}
      </DialogTitle>
      <DialogActions>
        <Button
          onClick={close}
          style={{
            background: colors.light_grey,
            color: colors.white,
            padding: '5px 20px',
          }}>
          No
        </Button>
        <Button
          onClick={handleDelete}
          disabled={loader}
          style={{
            color: colors.white,
            padding: '5px 20px',
          }}>
          {loader ? (
            <CircularProgress size={24} style={{ color: colors.primaryColor }} />
          ) : (
            'Yes'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
