import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ActionsMenu from '../../components/ActionMenu';
import BoxPagination from '../../components/Pagination';
import {colors} from '../../lib/util/StringUtil';
import {ExhibitiorContext} from '../../services/context/exhibitor/ExhibitorProvider';
import {Exhibitorcolumns} from './data';

export default function Exhibitors({id, exhibitionData}) {
  const {exhibitorPage, loadingExhibitors, selectedExhibitorPage, exhibitors} =
    useContext(ExhibitiorContext);
  const [exhibitorsData, setExhibitorsData] = useState([]);
  const [exhibitorDataLength, setExhibitorDataLength] = useState(0);
  const rowsPerPage = 50;
  const [totalResults, setTotalResults] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
    exhibitorPage(newPage);
  };

  useEffect(() => {
    setExhibitorsData(exhibitors?.exhibitors);
    setExhibitorDataLength(exhibitors?.totalItems);
    setTotalResults(exhibitors?.totalItems);
    const calculatedPageCount =
      Math.ceil(exhibitors?.totalItems / rowsPerPage) || 1;
    setPageCount(calculatedPageCount);
  }, [exhibitors?.currentPage, exhibitors?.totalItems]);
  const getRowCell = (column, value, row) => {
    const generateKey = () => `${row.id}-${column.id}`;
    switch (column.id) {
      case 'accessCode':
        return (
          <TableCell key={generateKey()} align={column.align}>
            {row.accessCode.code}
          </TableCell>
        );
      case 'action':
        return (
          <TableCell key={generateKey()} align={column.align}>
            <ActionsMenu
              exhibitorData={row}
              exhibitorId={row.id}
              exhibitionId={id}
            />
          </TableCell>
        );
      // will be added later
      // case 'resendMail':
      //   return (
      //     <TableCell key={generateKey()} align={column.align}>
      //       <Button variant="contained" endIcon={<SendIcon />}>
      //         Send
      //       </Button>
      //     </TableCell>
      //   );

      default:
        return (
          <TableCell key={generateKey()} align={column.align}>
            <Link
              style={styles.linkcolor}
              to={`/scanned-data/${row.id}`}
              state={{exhibitorData: row, exhibitionData: exhibitionData}}>
              {column.format && typeof value === 'number'
                ? column.format(value)
                : value}
            </Link>
          </TableCell>
        );
    }
  };

  return (
    <Paper elevation={0} sx={{overflow: 'hidden'}}>
      {loadingExhibitors ? (
        <Box
          display="flex"
          justifyContent="center"
          minHeight="500px"
          alignItems={'center'}>
          <CircularProgress size={30} style={{color: colors.primaryColor}} />
        </Box>
      ) : (
        <TableContainer className="tableboder CompanyName-exibitors">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {Exhibitorcolumns.map((column, index) => (
                  <TableCell
                    key={index}
                    align={column.align}
                    style={{minWidth: column.minWidth}}>
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {exhibitorDataLength > 0 ? (
                exhibitorsData.map(row => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {Exhibitorcolumns.map((column, index) => {
                      const value = row[column.id];
                      const cellData = getRowCell(column, value, row);
                      return cellData;
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Typography>No Data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {exhibitorDataLength > 50 && loadingExhibitors === false && (
        <BoxPagination
          pageCount={pageCount}
          currentPage={
            selectedExhibitorPage ? selectedExhibitorPage : currentPage
          }
          onChangePage={handlePageChange}
          totalResults={totalResults}
          rowsPerPage={rowsPerPage}
        />
      )}
    </Paper>
  );
}

const styles = {
  linkcolor: {
    color: colors.secondaryColor,
    textDecoration: 'none',
  },
  noDataTableRow: {margin: '20px 0px 20px 30px'},
};
