import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CAPLEAD_EXPORT_SCANNED_DATA,
  CAPLEAD_GET_SCANNED_DATA,
  CAPLEAD_SEACRH_SCANNED_DATA_URL,
} from '../../api';
import {Get, GetExport} from '../../axios';
import {UserContext} from '../user/UserProvider';
import {AlertMessageContext} from '../aletMessage/AlertMessageProvider';
import {useParams} from 'react-router';

export const ScannedDataContext = createContext();

const ScannedDataProvider = ({children}) => {
  const [scannedData, setScannedData] = useState([]);
  const {tokendata} = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const {showErrorMessage, showSuccessMessage} =
    useContext(AlertMessageContext);
  const [searchText, setSearchText] = useState('');
  const params = useParams();

  const getScannedData = useCallback(async () => {
    setLoading(true);
    try {
      const headers = {'x-access-token': tokendata};
      const url = CAPLEAD_GET_SCANNED_DATA + '/' + params.id;
      const response = await Get(url, headers);
      setScannedData(response.data.data);
    } catch (error) {
      showErrorMessage('Something went wrong');
    } finally {
      setLoading(false);
    }
  }, [tokendata]);

  const exportScannedData = async exhibitorId => {
    try {
      const headers = {'x-access-token': tokendata};
      const url = CAPLEAD_EXPORT_SCANNED_DATA + '/' + exhibitorId;
      const response = await GetExport(url, headers);
      const mainblob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const mainurl = URL.createObjectURL(mainblob);
      const disposition = response.headers['content-disposition'];
      const filename =
        disposition?.match(/filename=([^;]+)/i)?.[1]?.trim() || 'my-file.xlsx';
      const link = document.createElement('a');
      link.href = mainurl;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      showSuccessMessage('Scanned data exported successfully');
    } catch (error) {
      showErrorMessage('Something went wrong while exporting scanned data');
    }
  };

  const searchScannedData = async (exhibitorId, searchText) => {
    if (searchText === '') {
      getScannedData(params.id, params.eId);
    } else {
      const headers = {'x-access-token': tokendata};
      const url =
        CAPLEAD_SEACRH_SCANNED_DATA_URL +
        '?exhibitorId=' +
        exhibitorId +
        '&searchTerm=' +
        searchText;
      const response = await Get(url, headers);
      setScannedData(response.data.data);
    }
  };

  useEffect(() => {
    getScannedData(params.id, params.eId);
  }, []);

  return (
    <ScannedDataContext.Provider
      value={{
        getScannedData,
        scannedData,
        exportScannedData,
        searchText,
        setSearchText,
        searchScannedData,
        loading,
      }}>
      {children}
    </ScannedDataContext.Provider>
  );
};

export default ScannedDataProvider;
