import {CircularProgress} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, {useContext, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {colors} from '../../lib/util/StringUtil';
import {UserContext} from '../../services/context/user/UserProvider';
import {AlertMessageContext} from '../../services/context/aletMessage/AlertMessageProvider';

export default function AlertDialog({open, close}) {
  const {signOut} = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const {showSuccessMessage, showErrorMessage} =
    useContext(AlertMessageContext);
  const navigate = useNavigate();

  const onClickLogout = async () => {
    try {
      setLoader(true);
      await new Promise(resolve => setTimeout(resolve, 500)); // wait for 2 seconds
      await signOut();
      navigate('/');
      showSuccessMessage('You have successfully logged out');
      window.history.pushState(null, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, window.location.href);
      });
    } catch (err) {
      showErrorMessage('Logout Error');
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            width: '450px',
          },
        }}
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to logout ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={close}
            style={{
              background: colors.light_grey,
              color: colors.white,
              padding: '5px 20px',
            }}>
            No
          </Button>
          <Button
            variant="contained"
            disabled={loader}
            onClick={onClickLogout}
            style={{
              padding: '5px 20px',
            }}>
            {loader ? (
              <CircularProgress
                size={24}
                style={{color: colors.primaryColor}}
              />
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
