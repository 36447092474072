import {
  Button
} from '@mui/material';
import React, { useContext, useState } from 'react';
import AddExhibitorModal from './addExhibitor';
import AddVisitorModal from './addVisitor';
import { ExhibitiorContext } from '../../services/context/exhibitor/ExhibitorProvider';

export default function AddImport({ id }) {
  const [open, setOpen] = useState(false);
  const { currentTab } = useContext(ExhibitiorContext);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Add
      </Button>
      {currentTab === 0 ? open && <AddExhibitorModal open={open} handleClose={handleClose} id={id} />
        : open && <AddVisitorModal open={open} handleClose={handleClose} id={id} />

      }

    </div>
  );
}
