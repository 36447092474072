import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  CAPLEAD_ADD_EXHIBITION_URL,
  CAPLEAD_EXHIBITION_SEARCH_URL,
  CAPLEAD_GET_EXHIBITION_URL,
  CAPLEAD_UPDATE_EXHIBITION_URL,
} from '../../api';
import {Get, Post, Update} from '../../axios';
import {AlertMessageContext} from '../aletMessage/AlertMessageProvider';
import {UserContext} from '../user/UserProvider';

export const ExhibitionContext = createContext();

const ExhibitionProvider = ({children}) => {
  const [exhibitions, setExhibitions] = useState([]);
  const [tempData, setTempData] = useState([]);
  const {tokendata} = useContext(UserContext);
  const {showErrorMessage, showSuccessMessage} =
    useContext(AlertMessageContext);

  // this fun is for get all exhibitions from database
  const getExhibitions = useCallback(async () => {
    try {
      const headers = {'x-access-token': tokendata};
      const response = await Get(CAPLEAD_GET_EXHIBITION_URL, headers);
      setExhibitions(response?.data?.data);
      setTempData(response?.data?.data);
    } catch (error) {
      showErrorMessage('Exhibitions not fetched');
    }
  }, []);

 

  const addExhibition = async data => {
    try {
      const headers = {
        'x-access-token': tokendata,
      };
      const response = await Post(CAPLEAD_ADD_EXHIBITION_URL, data, headers);
      if (response.status.code === 201) {
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
      return response;
    } catch (error) {
      showErrorMessage('Exhibition api error');
    }
  };

  const updateExhibition = async (exhibitionId, data) => {
    try {
      const headers = {
        'x-access-token': tokendata,
      };
      const url = CAPLEAD_UPDATE_EXHIBITION_URL + '/' + exhibitionId;
      const response = await Update(url, data, headers);
      if (response.data.status.code === 200) {
        showSuccessMessage(response.data.status.message);
      } else {
        showErrorMessage(response.data.status.message);
      }
      return response;
    } catch (error) {
      showErrorMessage('Exhibition update  api error');
    }
  };

  const searchExhibition = data => {
    if (data === '') {
      getExhibitions();
    } else {
      const headers = {
        'x-access-token': tokendata,
      };
      const url = CAPLEAD_EXHIBITION_SEARCH_URL + '?searchTerm=' + data;
      Get(url, headers)
        .then(res => {
          setExhibitions(res.data.data.exhibitions);
        })
        .catch(error => {
          showErrorMessage('search Exhibitions not fetched');
        });
    }
  };

  // this useeffect is for call all exhibitions from database
  useEffect(() => {
    getExhibitions();
  }, []);
  return (
    <ExhibitionContext.Provider
      value={{
        getExhibitions,
        exhibitions,
        addExhibition,
        updateExhibition,
        searchExhibition,
        tempData,
      }}>
      {children}
    </ExhibitionContext.Provider>
  );
};

export default ExhibitionProvider;
