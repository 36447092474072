import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import Logo from '../../icons/logo';
import { colors } from '../../lib/util/StringUtil';
import { UserContext } from '../../services/context/user/UserProvider';
import './style.css';

export default function ResetPassword() {
  const {resetPasswordLinkGenerate} = useContext(UserContext);
  const [loader, setLoader] = useState(false);
  const [loginData, setLoginData] = useState({
    email: '',
  });

  const [error, setError] = useState({
    email: '',
  });
  const [showSkeleton, setShowSkeleton] = useState(false);

  const clearError = () => {
    setError({email: ''});
  };

  const onClickResetPasswordLinkGenerate = async (event) => {
    event.preventDefault();
    const {email} = loginData;

    if (email.trim() === '') {
      setError(prevError => ({
        ...prevError,
        email: 'Email is required',
      }));
    } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError(prevError => ({
        ...prevError,
        email: 'Please enter a valid email',
      }));
    } else {
      setLoader(true);
      await resetPasswordLinkGenerate(email);
      setLoginData({ email: '' }); 
      setLoader(false);
      setTimeout(() => {
        setLoader(false);
        window.location.href = '/';
      }, 3000);
    }
    
    setLoader(false);
  };

  const inputHandler = (evt) => {
    clearError();
    const { name, value } = evt.target;
    setLoginData((prevLoginData) => ({
      ...prevLoginData,
      [name]: value,      
    }));
  };


  return (
    <div className="container">
      <form onSubmit={onClickResetPasswordLinkGenerate}>
        <Box className="loginscreen">
          <Paper elevation={0} className="loginbox" variant="outlined">
            <Logo />
            <strong>Please enter your email for reset password</strong>

            {showSkeleton ? (
              <div className="skeletonloading">
                <Skeleton />
                <Skeleton />
              </div>
            ) : (
              <div className="loginloading">
                <TextField
                  fullWidth
                  label="Enter Email"
                  variant="outlined"
                  name="email"
                  value={loginData.email}
                  onChange={inputHandler}
                  error={Boolean(error.email)}
                  helperText={error.email}
                  autoComplete="email" 
                />
              </div>
            )}
            <Button
              fullWidth
              type="submit"
              className="loginbutton"
              variant="contained"
              disabled={loader}
              onClick={onClickResetPasswordLinkGenerate}>
              {loader ? (
                <CircularProgress
                  size={28}
                  style={{color: colors.primaryColor}}
                />
              ) : (
                'Send Link'
              )}
            </Button>
            <AlertMessage />
          </Paper>
        </Box>
      </form>
    </div>
  );
}
