import React from 'react';
import Logo from '../icons/logo';
import './style.css';
export default function Splash() {
  return (
    <div className="splash-screen">
      <Logo />
    </div>
  );
}
