export const colors = {
  primaryColor: '#04B983',
  primary_light: '#CBE2D9',
  secondaryColor: '#494A4C',
  blue: '#271E7B',
  black: '#000000',
  white: '#FFFFFF',
  grey: '#EBEBEB',
  light_grey: '#9E9E9E',
};
