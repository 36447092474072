import {  Divider, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import React, {useContext, useState} from 'react';
import AlertMessage from '../../components/AlertMessage/AlertMessage';
import Logo from '../../icons/logo';
import {colors} from '../../lib/util/StringUtil';
import {useLoginMode} from '../../services/context/loginMode/loginModeProvider';
import {UserContext} from '../../services/context/user/UserProvider';
import './style.css';

export default function Login() {
  const {signIn, organizerSignIn} = useContext(UserContext);
  const [loader, setLoader] = useState();
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const {loginMode, setLoginMode} = useLoginMode();
  const [loginLinkText, setLoginLinkText] = useState('Organizer Login?');
  const [showSkeleton, setShowSkeleton] = useState(false);

  const clearError = () => {
    setError({email: '', password: ''});
  };

  const onClickSignIn = async event => {
    event.preventDefault();
    const {email, password} = loginData;
    setLoader(true);
    if (email.trim() === '') {
      setError(prevError => ({
        ...prevError,
        email: 'Email is required',
      }));
    } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError(prevError => ({
        ...prevError,
        email: 'Please enter a valid email',
      }));
    } else if (password.trim() === '') {
      setError(prevError => ({
        ...prevError,
        password: 'Password is required',
      }));
    } else {
      if (loginMode === 'user') {
        await signIn(email, password);
      } else if (loginMode === 'organizer') {
        await organizerSignIn(email, password);
      }
    }
    setLoader(false);
  };

  const toggleLoginMode = () => {
    setShowSkeleton(true);
    setTimeout(() => {
      if (loginMode === 'user') {
        setLoginMode('organizer');
        setLoginLinkText('Admin Login?');
        setLoginData(prevLoginData => ({
          ...prevLoginData,
        }));
      } else if (loginMode === 'organizer') {
        setLoginMode('user');
        setLoginLinkText('Organizer Login?');
      }
      setShowSkeleton(false);
    }, 1000);
  };

  const inputHandler = evt => {
    clearError();
    const {name, value} = evt.target;
    setLoginData(prevLoginData => ({
      ...prevLoginData,
      [name]: value,
    }));
  };

  return (
    <div className="container">
      <form onSubmit={onClickSignIn}>
        <Box className="loginscreen">
          <Paper elevation={0} className="loginbox" variant="outlined">
            <Logo />
            <strong>Please enter your login credentials</strong>

            {showSkeleton ? (
              <div className="skeletonloading">
                <Skeleton />
                <Skeleton />
              </div>
            ) : (
              <div className="loginloading">
                <TextField
                  fullWidth
                  label="Email Id"
                  variant="outlined"
                  name="email"
                  value={loginData.email}
                  onChange={inputHandler}
                  error={Boolean(error.email)}
                  helperText={error.email}
                  autoComplete="email"
                />
                <TextField
                  fullWidth
                  label="Enter password"
                  variant="outlined"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={loginData.password}
                  onChange={inputHandler}
                  error={Boolean(error.password)}
                  helperText={error.password}
                  autoComplete="current-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            color: colors.primaryColor,
                            margin: '0px 0px 0px 10px',
                          }}
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            <Button
              fullWidth
              type="submit"
              className="loginbutton"
              variant="contained"
              disabled={loader}
              onClick={onClickSignIn}>
              {loader ? (
                <CircularProgress
                  size={24}
                  style={{color: colors.primaryColor}}
                />
              ) : (
                'Login'
              )}
            </Button>
            <div>
              <a className='forgot-password' href='/sendEmailResetLink'>Forgot Password?</a>
            </div>
            <Divider style={{width:'100%'}} />
            <div>
              <Button className="loginbutton" onClick={toggleLoginMode}>
                {loginLinkText}
              </Button>
            </div>
            <AlertMessage />
          </Paper>
        </Box>
      </form>
    </div>
  );
}
