import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StoreIcon from '@mui/icons-material/Store';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { Breadcrumbs, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../components/Header';
import AddImport from '../../components/InnerHeader';
import ExportFile from '../../components/InnerHeader/export';
import Importfile from '../../components/InnerHeader/import';
import Searchbar from '../../components/Searchbar';
import { ExhibitiorContext } from '../../services/context/exhibitor/ExhibitorProvider';
import { VisitorContext } from '../../services/context/visitor/VisitorProvider';
import Exhibitors from './exhibitors';
import './style.css';
import Visitors from './visitors';

import AlertMessage from '../../components/AlertMessage/AlertMessage';
const Exhibitionsdetails = () => {
  const { state } = useLocation();
  const { currentTab, setCurrentTab, exhibitionById } = useContext(ExhibitiorContext);
  const [exhibitionData, setExhibitionData] = useState({});

  useEffect(() => {
    if (state) {;
      setExhibitionData(state);
    } else {
      setExhibitionData(exhibitionById);
    }
  }, []);


  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Fragment>
      <Header />
      <AlertMessage />
      <div className="container">
        <Box className="breadcrumbs">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <Link key="1" to="/">
              <HomeIcon />
            </Link>
            <Typography>
              {exhibitionData.name}
            </Typography>
          </Breadcrumbs>
          {exhibitionData && (
            <Box className="createingbar">
              <VisitorOrExhibitorSearch exhibitionId={exhibitionData.id} />
              <AddImport value={currentTab} id={exhibitionData.id} />
              <Importfile value={currentTab} id={exhibitionData.id} />
              <ExportFile
                value={currentTab}
                id={exhibitionData.id}
                name={exhibitionData.name}
              />
              {/*
            <Box className="information">
              <NotificationsNoneIcon />
              <InfoOutlinedIcon />
            </Box>*/}
            </Box>
          )}
        </Box>
        <Box className="table-exhibitors" sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleChange}>
              <Tab icon={<StoreIcon />} label="Exhibitors" {...a11yProps(0)} />
              <Tab
                icon={<SupervisorAccountIcon />}
                label="Visitors"
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <TabPanel value={currentTab} index={0}>
            <Exhibitors
              id={exhibitionData.id}
              exhibitionData={exhibitionData}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <Visitors id={exhibitionData.id} tabValue={currentTab} />
          </TabPanel>
        </Box>
      </div>
    </Fragment>
  );
}
export default memo(Exhibitionsdetails);

const VisitorOrExhibitorSearch = ({ exhibitionId }) => {
  const { searchExhibitors, currentTab, searchText, setSearchText } = useContext(ExhibitiorContext);
  const prevTabRef = useRef(currentTab);
  const { searchVisitors } = useContext(VisitorContext);
  const callSearchApi = (tabValue, searchParams) => {
    if (tabValue == 0) {
      searchExhibitors(exhibitionId, 1, searchParams);
    } else {
      searchVisitors(exhibitionId, 1, searchParams)
    }
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      callSearchApi(currentTab, searchText);
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  useEffect(() => {
    if (searchText) {
      callSearchApi(prevTabRef.current, '');
      setSearchText('');
      prevTabRef.current = currentTab
    }
  }, [currentTab]);

  const handleInputChange = event => {
    setSearchText(event.target.value);
  };
  return <Searchbar onChange={handleInputChange} searchText={searchText} />;
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};