import {CircularProgress} from '@material-ui/core';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {Fragment, useContext, useState} from 'react';
import BoxPagination from '../../components/Pagination';
import {ScannedDataContext} from '../../services/context/scannedData/ScannedDataProvider';
import {scannedColumns} from './data';
import {colors} from '../../lib/util/StringUtil';

export default function ScannedData() {
  const {scannedData, loading} = useContext(ScannedDataContext);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const scannedDataLength = scannedData?.length || 0;
  const rowsPerPage = 10;
  const totalResults = scannedDataLength;
  const pageCount = Math.ceil(totalResults / rowsPerPage) || 1;
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalResults);

  const getRowCell = (column, value, row, rowIndex) => {
    return (
      <TableCell key={`${row.id}-${column.id}`} align={column.align}>
        {column.format && typeof value === 'number'
          ? column.format(value)
          : value}
      </TableCell>
    );
  };

  return (
    <Paper elevation={0} sx={{overflow: 'hidden'}}>
      {loading ? (
        <Box display="flex" justifyContent="center" minHeight="500px">
          <CircularProgress size={30} style={{color: colors.primaryColor}} />
        </Box>
      ) : (
        <Fragment>
          <TableContainer className="all-exhibitions company-detailstable">
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {scannedColumns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{minWidth: column.minWidth}}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {scannedData?.length > 0 ? (
                <TableBody>
                  {scannedData
                    .slice(startIndex, endIndex + 1)
                    .map((row, rowIndex) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}>
                        {scannedColumns.map(column => {
                          const value = row[column.id];
                          const cellData = getRowCell(
                            column,
                            value,
                            row,
                            rowIndex,
                          );
                          return cellData;
                        })}
                      </TableRow>
                    ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={scannedColumns?.length}
                      style={{color: colors.black, justifyContent: 'center'}}>
                      <Typography variant="h2">No data Found</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
          {pageCount > 1 && (
            <BoxPagination
              pageCount={pageCount}
              currentPage={currentPage}
              onChangePage={handlePageChange}
              totalResults={totalResults}
              rowsPerPage={rowsPerPage}
            />
          )}
        </Fragment>
      )}
    </Paper>
  );
}
