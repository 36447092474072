import {MoreVertOutlined} from '@mui/icons-material';
import {IconButton, Menu, MenuItem} from '@mui/material';
import React, {Fragment, useState} from 'react';
import CreateExhibitions from '../CreateExhibitions';

export default function ActionMenu({data}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [servicedetails, setServicedetails] = useState(false);
  const onClickView = () => {
    setServicedetails(true);
    handleClose();
  };

  const [openmodal, setOpenmodal] = useState(false);
  const handleClickOpen = () => {
    setOpenmodal(true);
  };
  const handleClosemodal = () => {
    setOpenmodal(false);
    handleClose();
  };

  return (
    <Fragment>
      <IconButton
        className="cardaction"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
        <MoreVertOutlined />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClickView}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem onClick={handleClickOpen}>Edit</MenuItem>
        {/* <MenuItem onClick={handleClose}>Delete</MenuItem> */}
      </Menu>   
      {openmodal && (
        <CreateExhibitions
        actionclose={handleClosemodal}
        openmodal={openmodal}
        data={data}
        isEdit={true}
      />
      )}
    </Fragment>
  );
}
