import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../lib/util/StringUtil';
import { ExhibitiorContext } from '../../services/context/exhibitor/ExhibitorProvider';
import { VisitorContext } from '../../services/context/visitor/VisitorProvider';

export default function Importfile({ value, id }) {
  const { addBulkExhibitors, getExhibitors } = useContext(ExhibitiorContext);
  const { addBulkVisitors, getVisitors } = useContext(VisitorContext);
  const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // it is used  to upload xlsx file
  const [file, setFile] = useState(null);
  const handleFileUpload = async event => {
    const file = event.target.files[0];
    setFile(file);
  };

  // it is used to import or upload xlsx file for exhibitors & visitors
  const handleImport = async () => {
    try {
      setLoader(true);
      if (value === 0) {
        await addBulkExhibitors(id, file);
        await getExhibitors(id, 1);
      } else {
        await addBulkVisitors(id, file);
        await getVisitors(id, 1);
      }
      setOpen(false);
      setFile(null);
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Import
      </Button>
      <Dialog
        className="dataimport"
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '520px',
          },
        }}>
        <DialogTitle sx={styles.dilogtitle}>
          {value === 0 ? 'Import exhibitor’s data' : 'Import visitor’s data'}
        </DialogTitle>
        <DialogContent style={{ color: colors.black }}>
          In order to import your data properly, please make sure it is arranged
          as shown in this{' '}
          <Link style={{ color: colors.blue }}>
            sample file.
          </Link>
          <Input
            sx={styles.dataupload}
            type="file"
            onChange={handleFileUpload}
            inputProps={{
              accept: ".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={styles.cancelbtn}>
            Cancel
          </Button>
          <Button
            style={{ width: 100 }}
            variant="contained"
            onClick={handleImport}
            disabled={!file || loader}>
            {loader ? (
              <CircularProgress
                size={24}
                style={{ color: colors.primaryColor }}
              />
            ) : (
              'Upload'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = {
  dataupload: {
    border: 'solid 1px #888',
    width: '100%',
    marginTop: '20px',
    borderRadius: '5px',
    padding: '3px 10px',
    cursor: 'pointer',
  },
  dilogtitle: {
    borderBottom: 'none!important',
    paddingBottom: '0!important',
  },
  cancelbtn: {
    background: colors.white,
    border: `1px solid ${colors.secondaryColor}`,
    color: colors.secondaryColor,
    '&:hover': {
      background: colors.white,
    },
  },
};
