import {ThemeProvider} from '@emotion/react';
import React, {useEffect, useState} from 'react';
import Navigation from './navigator';
import UserProvider from './services/context/user/UserProvider';
import Splash from './splash';
import {theme} from './theme';
import AlertMessageProvider from './services/context/aletMessage/AlertMessageProvider';
import {LoginModeProvider} from './services/context/loginMode/loginModeProvider';

function App() {
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 1000);
  }, []);

  return (
    <div className="App">
      {showSplashScreen ? (
        <Splash />
      ) : (
        <ThemeProvider theme={theme}>
          <AlertMessageProvider>
            <UserProvider>
              <LoginModeProvider>
                <Navigation />
              </LoginModeProvider>
            </UserProvider>
          </AlertMessageProvider>
        </ThemeProvider>
      )}
    </div>
  );
}

export default App;
