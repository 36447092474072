import jwt_decode from 'jwt-decode';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  CAPLEAD_LOGIN_URL,
  CAPLEAD_LOGOUT_URL,
  CAPLEAD_ORGANIZER_LOGIN_URL,
  CAPLEAD_RESET_PASSWORD_LINK_GENERATE_URL,
  CAPLEAD_RESET_PASSWORD_URL,
} from '../../api';
import { Post } from '../../axios';
import { AlertMessageContext } from '../aletMessage/AlertMessageProvider';

export const UserContext = createContext();

const UserProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [tokendata, setTokendata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState('');
  const {showSuccessMessage, showErrorMessage} =
    useContext(AlertMessageContext);
  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  };

  const setUserInformation = cookieToken => {
    if (cookieToken) {
      try {
        const userdata = jwt_decode(cookieToken);
        setUser(userdata);
        setTokendata(cookieToken);
      } catch (error) {
        showErrorMessage('Invalid token', error);
      }
    }
  };

  useEffect(() => {
    const cookieToken = getCookie('token');
    setUserInformation(cookieToken);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const cookies = document.cookie.split('; ');
    const usernameCookie = cookies.find(cookie =>
      cookie.startsWith('username='),
    );
    if (usernameCookie) {
      const [, username] = usernameCookie.split('=');
      setUsername(username);
    }
  }, []);

  const signIn = async (email, password) => {
    try {
      const response = await Post(CAPLEAD_LOGIN_URL, {email, password});
      if (response.status.type === 'Success') {
        const token = response?.data?.token;
        const username = response?.name;
        document.cookie = `token=${token}; expires=${new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000,
        ).toUTCString()}; path=/`;
        document.cookie = `username=${username}; expires=${new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000,
        ).toUTCString()}; path=/`;
        setUserInformation(token);
        setUsername(username);
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
    } catch (error) {
      throw error;
    }
  };

  const organizerSignIn = async (email, password, exhibitionName) => {
    try {
      const response = await Post(CAPLEAD_ORGANIZER_LOGIN_URL, {
        email,
        password,
        exhibitionName,
      });
      if (response.status.type === 'Success') {
        const token = response?.data?.token;
        const username = response?.name;
        document.cookie = `token=${token}; expires=${new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000,
        ).toUTCString()}; path=/`;
        document.cookie = `username=${username}; expires=${new Date(
          Date.now() + 30 * 24 * 60 * 60 * 1000,
        ).toUTCString()}; path=/`;
        setUserInformation(token);
        setUsername(username);
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
    } catch (error) {
      throw error;
    }
  };
  const signOut = async () => {
    try {
      const headers = {'x-access-token': tokendata};
      const response = await Post(CAPLEAD_LOGOUT_URL, {}, headers);
      if (response.status.type === 'Success') {
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
        document.cookie =
          'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        setUsername(null);
        setUserInformation(null);
        setTokendata(null);
      } else {
        throw new Error('Logout failed');
      }
    } catch (error) {
      throw error;
    }
  };

  const resetPasswordLinkGenerate = async email => {
    try {
      const response = await Post(CAPLEAD_RESET_PASSWORD_LINK_GENERATE_URL, {
        email,
      });
      if (response.status.type === 'Success') {
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
    } catch (error) {
      throw error;
    }
  };

  const resetPassword = async (password, confirmPassword, resetToken) => {
    const requestBody = {
      password,
      confirmPassword,
    };
    try {

      const response = await Post(
        CAPLEAD_RESET_PASSWORD_URL + '/' + resetToken,
        requestBody,
      );

      if (response.status.type === 'Success') {
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
    } catch (error) {
      showErrorMessage("Password reset  failed", error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        signIn,
        tokendata,
        loading,
        signOut,
        getCookie,
        username,
        organizerSignIn,
        resetPasswordLinkGenerate,
        resetPassword,
      }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
