import {useContext} from 'react';
import {UserContext} from '../services/context/user/UserProvider';

export function CanAccessToCreatExhibition() {
  const {user} = useContext(UserContext);
  if (user.role == 'SUPER ADMIN') {
    return true;
  }
  return false;
}

export function CanAccessToAction() {
  const {user} = useContext(UserContext);
  if (user.role == 'SUPER ADMIN') {
    return true;
  }
  return false;
}
