import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CanAccessToAction } from '../../accessManager';
import { ExhibitionContext } from '../../services/context/exhibition/ExhibitionProvider';
import BoxPagination from '../Pagination';
import ActionMenu from './actionMenu';

export default function ExhibitionsCards() {
  const {exhibitions} = useContext(ExhibitionContext);
  const [cardData, setCardData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const newCardData = [];
    if (exhibitions !== undefined) {
      for (let i = 0; i < exhibitions?.length; i++) {
        const isoStartDate = exhibitions[i]?.startDate;
        const isoEndDate = exhibitions[i]?.endDate;
        if (isoStartDate && isoEndDate) {
          const startDate = new Date(isoStartDate);
          const endDate = new Date(isoEndDate);

          const formattedStartDate = dayjs(startDate).format('MMM DD YYYY');
          const formattedEndDate = dayjs(endDate)
            .subtract(1, 'day')
            .format('MMM DD YYYY');

          newCardData.push({
            ...exhibitions[i],
            formattedStartDate,
            formattedEndDate,
          });
        }
      }
    }
    setCardData(newCardData);
  }, [exhibitions]);

  const rowsPerPage = 9;
  const totalResults = cardData?.length;
  const pageCount = Math.ceil(totalResults / rowsPerPage) || 1;
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage - 1, totalResults);

  return (
    <Box>
      <Box className="all-exhibitions">
        {/* <Typography variant="h1" className="title1">
          All exhibitions <ArrowDropDownOutlinedIcon />
        </Typography> */}
        <Box className="cardspace">
          {cardData.slice(startIndex, endIndex + 1).map((data, index) => {
            const {name, formattedStartDate, formattedEndDate, venue, id} =
              data;
            return (
              <Card key={id}>
                {CanAccessToAction() && <ActionMenu data={data} />}
                <Link
                  style={{textDecoration: 'none'}}
                  state={data}
                  to={`/exhibitions-details/${id}`}>
                  <CardActionArea>
                    <CardContent>
                      <Typography variant="h2">{name}</Typography>
                      <Typography variant="body2">
                        <DateRangeIcon />
                        <Typography variant="caption">
                          {formattedStartDate} - {formattedEndDate}
                        </Typography>
                      </Typography>
                      <Typography variant="body2">
                        <LocationOnIcon />
                        <Typography variant="caption">{venue}</Typography>
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            );
          })}
        </Box>
      </Box>
      {exhibitions?.length > 0 && (
        <Box className="pagination">
          <BoxPagination
            pageCount={pageCount}
            currentPage={currentPage}
            onChangePage={handlePageChange}
            totalResults={totalResults}
            rowsPerPage={rowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
