import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { colors } from '../../lib/util/StringUtil';
import { ExhibitionContext } from '../../services/context/exhibition/ExhibitionProvider';
export default function CreateExhibitions({
  openmodal,
  actionclose,
  data,
  isEdit,
}) {
  const exhibitionId = isEdit ? data.id : '';
  const {addExhibition, getExhibitions, updateExhibition} =
    useContext(ExhibitionContext);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const initialStartDate = isEdit ? dayjs(data.startDate).toDate() : new Date();
  const initialEndDate = isEdit
    ? dayjs(data.endDate).subtract(1, 'day').toDate()
    : new Date();

  const [exhibitionData, setExhibitionData] = useState({
    exhibitionName: isEdit ? data.name : '',
    location: isEdit ? data.venue : '',
    organizer: isEdit ? data?.organizer?.organizer : '',
    startDate: initialStartDate,
    endDate: initialEndDate,
    contactPerson: isEdit ? data?.organizer?.contactPerson : '',
    mobileNumber: isEdit ? data?.organizer?.mobileNumber : '',
    email: isEdit ? data?.organizer?.email : '',
  });

  useEffect(() => {
    if (isEdit && data) {
      const editedStartDate = dayjs(data.startDate).toDate();
      const editedEndDate = dayjs(data.endDate).subtract(1, 'day').toDate();

      setExhibitionData({
        ...exhibitionData,
        startDate: editedStartDate,
        endDate: editedEndDate,
      });
    }
  }, [isEdit, data]);

  const handleDateChange = (date, name) => {
    if (name === 'startDate') {
      setExhibitionData({
        ...exhibitionData,
        startDate: date,
      });
    } else if (name === 'endDate') {
      setExhibitionData({
        ...exhibitionData,
        endDate: date,
      });
    }
  };

  const handleCreate = async () => {
    setLoader(true);
    const formattedStartDate = dayjs(exhibitionData.startDate).format(
      'MM/DD/YYYY',
    );
    const formattedEndDate = dayjs(exhibitionData.endDate)
      .endOf('day')
      .format('MM/DD/YYYY');

    let eventData = {
      name: exhibitionData.exhibitionName,
      venue: exhibitionData.location,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      organizer: exhibitionData.organizer,
      contactPerson: exhibitionData.contactPerson,
      mobileNumber: exhibitionData.mobileNumber,
      email: exhibitionData.email,
    };

    try {
      if (isEdit) {
        await updateExhibition(exhibitionId, eventData);
      } else {
        await addExhibition(eventData);
      }
      setTimeout(() => {
        getExhibitions();
        actionclose();
        setLoader(false);
      }, 1000);
    } catch (error) {
      const errorMessage = isEdit
        ? `data not updated => ${error.message}`
        : `exhibition error => ${error.message}`;
      setError(errorMessage);
      setLoader(false);
    }
  };

  const inputHandler = event => {
    const value = event.target.value;
    setExhibitionData({
      ...exhibitionData,
      [event.target.name]: value,
    });
  };

  return (
    <Dialog
      open={openmodal}
      onClose={actionclose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {'Create new exhibition'}
      </DialogTitle>

      <DialogContent>
        <Box className="createnewexhibition">
          <TextField
            fullWidth
            label="Exhibition name"
            name="exhibitionName"
            value={exhibitionData.exhibitionName}
            onChange={inputHandler}
          />

          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              label="Location"
              name="location"
              value={exhibitionData.location}
              onChange={inputHandler}
              inputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <LocationOnIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>

        <Box className="createnewexhibition">
          <Box className="daterange-validation">
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  value={dayjs(exhibitionData.startDate)}
                  onChange={date => handleDateChange(date, 'startDate')}
                  label="Enter start date"
                />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  onChange={date => handleDateChange(date, 'endDate')}
                  label="Enter end date"
                  value={dayjs(exhibitionData.endDate)}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              label="Organizer"
              name="organizer"
              value={exhibitionData.organizer}
              onChange={inputHandler}
              inputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <CorporateFareIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>

        <Box className="createnewexhibition">
          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              label="Contact person"
              name="contactPerson"
              value={exhibitionData.contactPerson}
              onChange={inputHandler}
              inputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <PersonOutlineOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              label="Mobile Number"
              name="mobileNumber"
              value={exhibitionData.mobileNumber}
              onChange={inputHandler}
              inputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <LocalPhoneOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={exhibitionData.email}
              onChange={inputHandler}
              inputProps={{
                endadornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <MailOutlineOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={actionclose} sx={styles.btnhide}>
          Cancel
        </Button>
        <Button
          disabled={loader}
          type="submit"
          variant="contained"
          onClick={handleCreate}
          autoFocus>
          {isEdit ? (
            <Fragment>
              {loader ? (
                <CircularProgress
                  size={28}
                  style={{color: colors.primaryColor}}
                />
              ) : (
                'Save'
              )}
            </Fragment>
          ) : (
            <Fragment>
              {loader ? (
                <CircularProgress
                  size={28}
                  style={{color: colors.primaryColor}}
                />
              ) : (
                'Create'
              )}
            </Fragment>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  btnhide: {
    background: colors.white,
    border: `1px solid ${colors.secondaryColor}`,
    color: colors.secondaryColor,
    '&:hover': {
      background: colors.white,
    },
  },
};
